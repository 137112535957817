import './App.css';
import Login from "./components/auth/Login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Forgot from './components/auth/Forgot';
import Gog from './components/guardofguards/Gog';
import AddDevice from './components/guardofguards/AddDevice';
import ViewDevice from './components/guardofguards/ViewDevice';
import EditDevice from './components/guardofguards/EditDevice';
import SearchUser from './components/usermanager/SearchUser';
import AddNewUser from './components/usermanager/AddNewUser';
import {URL} from './components/auth/Container';
import ViewUser from './components/usermanager/ViewUser';
import EditUser from './components/usermanager/EditUser';
import AuthGuard from './components/AuthGuard';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<AuthGuard/>}>
            <Route path="/guardmanagement" element={<Gog url={URL}/>}/>
            <Route path="/view/device" element={<ViewDevice url={URL}/>}/>

            {/* {JSON.parse(window.sessionStorage.getItem("User"))?.user_type === "Admin" &&
              <> */}
                <Route path="/add/device" element={<AddDevice url={URL}/>}/>
                <Route path="/edit/device" element={<EditDevice url={URL}/>}/>
                <Route path="/user/management" element={<SearchUser/>}/>
                <Route path="/add/user" element={<AddNewUser/>}/>
                <Route path="/view/user/:id" element={<ViewUser/>}/>
                <Route path="/edit/user/:id" element={<EditUser/>}/>
              {/* </>
            } */}
          </Route>
          <Route path="/" element={<Login url={URL}/>}/>
          <Route path="/forgot" element={<Forgot url={URL}/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
