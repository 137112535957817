import React from 'react'
import "./TableView.css";
import "./Blockchain.css";

export default function BlockChain(props) {
  return (
    <table>
        <thead >
          <tr id = "blockchainid">
            <th style={{width:"100rem"}}>BlockChain Explorer</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{textAlign: "left"}}>
              <div className="container">
              {props.apidata.results.map((x) => {
                var qrcodeout;
                if (x.qr_code_out !== null){
                  qrcodeout = props.url+x.qr_code_out
                }else{
                  qrcodeout = ""
                }

                return (
                  <div key={x.id}>
                    {props?.searchapply?(
                      (props?.status === "Available")?(
                        <div className="row mediaQueryBorder">
                          <div className="col-lg-3 col-md-12 col-sm-12" style={{textAlign: "center"}}>
                            <img src={`${props.url}${x.qr_code_in}`} height="130px" width="130px" alt="" />
                          </div>
                          <div className="col-lg-9 col-md-12 col-sm-12 mediaquerydesign" >
                            <p className='mediaquerydesign'><b>Created Date&Time: </b>{x.in_time}<br/>
                            <b>Location: </b>{x.location}<br/>
                            <b>Status: </b>Available<br/>
                            <b>Tx Hash: </b>{x.tx_hash_in}<br/>
                            <b>Block Hash: </b>{x.block_hash_in}</p>
                          </div>
                        </div>
                      ):(
                        <div className="row mediaQueryBorder">
                        <div className="col-lg-3 col-md-12 col-sm-12" style={{textAlign: "center"}}>
                          <img src={qrcodeout} height="130px" width="130px" alt="" />
                        </div>
                        <div  id = "blockdiv"  className="col-lg-9 col-md-12 col-sm-12" >
                          <p className='mediaquerydesign'><b>Created Date&Time: </b>{x.out_time}<br/>
                          <b>Location: </b>{x.location}<br/>
                          <b>Status: </b>Unavailable<br/>
                          <b>Tx Hash: </b>{x.tx_hash_out}<br/>
                          <b>Block Hash: </b>{x.block_hash_out}</p>
                        </div>
                      </div>
                      )
                    ):(
                      <>
                        {(x?.state === "True")?(
                        <div className="row mediaQueryBorder">
                          <div className="col-lg-3 col-md-12 col-sm-12" style={{textAlign: "center"}}>
                            <img src={`${props.url}${x.qr_code_in}`} height="130px" width="130px" alt="" />
                          </div>
                          <div className="col-lg-9 col-md-12 col-sm-12 mediaquerydesign" >
                            <p className='mediaquerydesign'><b>Created Date&Time: </b>{x.in_time}<br/>
                            <b>Location: </b>{x.location}<br/>
                            <b>Status: </b>Available<br/>
                            <b>Tx Hash: </b>{x.tx_hash_in}<br/>
                            <b>Block Hash: </b>{x.block_hash_in}</p>
                          </div>
                        </div>
                        ):(
                        <div className="row mediaQueryBorder">
                          <div className="col-lg-3 col-md-12 col-sm-12" style={{textAlign: "center"}}>
                            <img src={qrcodeout} height="130px" width="130px" alt="" />
                          </div>
                          <div  id = "blockdiv"  className="col-lg-9 col-md-12 col-sm-12" >
                            <p className='mediaquerydesign'>
                            <b>Created Date&Time: </b>{x.out_time}<br/>
                            <b>Location: </b>{x.location}<br/>
                            <b>Status: </b>Unavailable<br/>
                            <b>Tx Hash: </b>{x.tx_hash_out}<br/>
                            <b>Block Hash: </b>{x.block_hash_out}</p>
                          </div>
                        </div>
                        )}
                    </>
                    )
                    }
                  </div>
                  );
                })
              }
              </div>
            </td>
          </tr>
        </tbody>
    </table>
  )
}
