import React from 'react'
import './Heading.css'

export default function Heading(props) {
    const headingStyle = {
        background: "#252A34",
        padding: "0.5rem",
        margin: "0 0 1rem 0",
        color: "#fff",
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
       
    }
    return (
        <div>
            <h1 id='heading' style={headingStyle}>{props.title}</h1>
        </div>
    )
}
