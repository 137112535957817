import React, {useEffect, useState } from 'react'
import Heading from '../Heading';
import UserManagement from './UserManagement';
import SideBar from '../SideBar';
import { Link } from 'react-router-dom';
import $ from 'jquery';

export default function SearchUser() {
  const [fullname, Setfullname] = useState();
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [apifromDate, setapifromDate] = useState("");
  const [apitoDate, setapitoDate] = useState("");

  const fullnameHandler = event => Setfullname(event.target.value);
  const fromDateHandler = event => setfromDate(event.target.value);
  const toDateHandler = event => settoDate(event.target.value);

  const inputStyle = {
    padding: "0.4rem",
    margin: "0",
    width: "12rem"
  }
  const shadow = {
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px"
  }

  const clearDeafultHandler = () => window.location.reload(false);

  const formSearchHandlerSet = event => {
    event.preventDefault();
    setapifromDate(fromDate);
    setapitoDate(toDate);
  } 

  useEffect(() => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1;
    var yyyy = today.getFullYear();
    if(dd<10){
      dd='0'+dd
    } 
    if(mm<10){
      mm='0'+mm
    } 
    today = yyyy+'-'+mm+'-'+dd;
    
    $("#userfromid").on('click',function(){
      $("#userfromid").attr("max",today)
    })
    $("#userfromid").change(function(){
      let min = $("#userfromid").val()
      $("#usertoid").val("");
      $("#usertoid").attr("min",min)
      $("#usertoid").attr("max",today)
    })
    $("#usertoid").on('click',function(){
      $("#usertoid").attr("max",today)
    })
  }, [])

  return (
    <SideBar childComponent={
        <div className="display: flex justify-content: center MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-3 MuiGrid-align-items-xs-center MuiGrid-justify-content-xs-center">
          
            <Heading title="User Management"/>
            <form style={{textAlign: "left", margin: "3rem"}} onSubmit={formSearchHandlerSet}>
            <div className="MuiBox-root jss113 jss107 ">
            <div class="col-sm-12 d-grid gap-2 d-md-flex justify-content-md-end">
            <Link to="/add/user" className='btn btn-light me-md-2' style={shadow}>Add New User</Link>
            </div>
            <div className="row MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 MuiGrid-grid-md-2 MuiGrid-grid-lg-2 mx-2 my-2">
                <div className="col-md-3 col-sm-6">
                <label>Name:</label>
                  <input type="text" value={fullname} onChange={fullnameHandler} maxLength={25} minLength={2} style={inputStyle}  placeholder="Search by Name"/>
                  </div>
                  <div className="col-md-3 col-sm-6">
                  <label>From:</label>
                  <input type="date" id="userfromid" value={fromDate} onChange={fromDateHandler} className="btn btn-light mx-3 my-2" required/>
                  </div>
                  <div className="col-md-3 col-sm-6">
                  <label>To:</label>
                  <input type="date" value={toDate} onChange={toDateHandler} id="usertoid" className="btn btn-light mx-3 my-2" required/>
                  </div>
                  <div className="col-md-3 col-sm-6">
                  <button style={shadow} className='btn btn-light mx-3 my-2'>Submit</button>
                  <button style={shadow} onClick={clearDeafultHandler} className='btn btn-light mx-3 my-2'>Clear</button>
                </div>
                </div>
                </div>
            </form>
            <UserManagement fullname={fullname} apifromDate={apifromDate} apitoDate= {apitoDate}/>
        </div>
    }/>
  )
}
