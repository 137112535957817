import React, {useState} from 'react'
import SideBar from '../SideBar';
import './AddDevice.css';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loader from '../Loader';
import {URL} from '../auth/Container';
import Heading from '../Heading';

export default function AddDevice() {
  const navigate = useNavigate();
  const [loader, changeLoader] = useState();
  const [txhash, updatetxhash] = useState();
  const [blockhash, updateblockhash] = useState();

  const generateBlockChain = event => {
    changeLoader(<Loader leftm="35rem"/>)
    event.preventDefault();
    const deviceid = document.getElementById("deviceid").value
    const location = document.getElementById("location").value
    const ip_address = document.getElementById("ip_address").value
    const device_type = document.getElementById("device_type").value

    const generate_hax = {
      device_id: (deviceid)? deviceid: "",
      device_type: (device_type) ? device_type: "",
      location: (location)? location:"",
      ip_address: (ip_address)? ip_address: ""
    }
    document.getElementById("deviceid").setAttribute("readonly", true);
    document.getElementById("location").setAttribute("readonly", true);
    document.getElementById("ip_address").setAttribute("readonly", true);
    document.getElementById("device_type").setAttribute("readonly", true);
    document.getElementById("hashGenerateBtn").disabled = true;

    axios.post(URL+"/add-new-device/", generate_hax)
    .then(function (res) {
      changeLoader();
      updatetxhash(res.data.data.tx_hash);
      updateblockhash(res.data.data.block_hash);

    })
    .catch(function (error) {
      changeLoader();
      document.getElementById("hashGenerateBtn").disabled = false;
      alert(error.response.data.message);
    });
  }

  const AdddeviceFormHandler = event => {
    event.preventDefault();
    const deviceid = document.getElementById("deviceid").value
    const location = document.getElementById("location").value
    const ip_address = document.getElementById("ip_address").value
    const device_type = document.getElementById("device_type").value

    const apibody = {
      tx_hash: txhash,
      block_hash: blockhash,
      device_id: deviceid,
      location: location,
      ip_address: ip_address,
      device_type: device_type
    }

    axios.post(URL+"/add-laststage-device/", apibody)
    .then(function (res) {
      navigate('/guardmanagement');
    })
    .catch(function (error) {
    });
  }
  const mystyle = {
    width: "11rem",
    height: "30px",
    
  };
  
  return (
    <SideBar childComponent={
    <>
      <Heading title="Guard of Guards"/>
      <div className="container mx-4 my-4" style={{maxWidth: "96%"}}>
        {loader}
        <h2 style={{color: "#000" , mystyle}}><u>Choose Device Type :</u></h2>
        <select name="device" id="device_type" style={mystyle}>
              <option value="">select</option>
              <option value="Motion sensor">Motion sensor</option>
              <option value="Camera">Camera</option>
          
        </select>
        <form onSubmit={AdddeviceFormHandler}>
          <div className="row">
            <div className="col-25">
              <label for="fname">Device ID / Camera Url</label>
            </div>
            <div className="col-75">
              <input id="deviceid" className="inputClass" type="text" placeholder="Enter New Device Id / Camera Url"/>
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <label for="lname">Location</label>
            </div>
            <div className="col-75">
              <input id="location" className="inputClass" type="text" placeholder="Enter New Location"/>
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <label for="lname">IP Address</label>
            </div>
            <div className="col-75">
              <input id="ip_address" className="inputClass" type="text" placeholder="Enter New IP Address"/>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-25">
              <label for="lname">Device Type</label>
            
            </div>
            <div className="col-75">
              <input id="device_type" className="inputClass" type="text" placeholder="Enter New Device Type"/>
            </div>
          </div> */}
          <div className="row">
            <div className="col-25">
              <label>Device Blockchain</label>
            </div>
            <div className="col-75">
              <input className="inputClass" type="text" value={txhash} placeholder="Auto Generate" readOnly required/>
            </div>
            <div className="col-50">
              <button type="submit" id="hashGenerateBtn" className="btn btn-dark mx-2 my-2" onClick={generateBlockChain}>Generate Blockchain</button>
            </div>
          </div>

          <div className="row my-4 mx-4">
          <table>
            <thead>
              <tr>
                <th>DEVICE INSTALLATION STATUS</th>
                <th>OPERATIONS</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Installation Checked</td>
                <td><input type="checkbox" required/></td>
              </tr>

              <tr>
                <td>Networking Protocol Checked</td>
                <td><input type="checkbox" required/></td>
              </tr>

              <tr>
                <td>Data Communication Checked</td>
                <td><input type="checkbox" required/></td>
              </tr>
            </tbody>
          </table>
          </div>
          <div className="row">
            <div className="col-50">
              <Link to='/guardmanagement' className="btn btn-dark mx-2" style={{width: "5rem"}}>Back</Link>
              <button type="submit" className="btn btn-dark mx-2" style={{width: "5rem"}}>Submit</button>
            </div>
          </div>
        </form>
      </div>
    </>
    }/>
  );
}
