import React, { useState, useEffect } from 'react'
import './Login.css'
import logo from '../../customlogo/mobiloitte.png';
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import Loader from '../Loader';


export default function Login(props) {
  const navigate = useNavigate();
  const location = useLocation();  
  const [alertCase, alertCaseChange] = useState("");
  const [loader, setLoader] = useState();
  
  // Email field validation..
  const [email, emailChange] = useState("");
  const [emailerror, emailsetError] = useState(null);
  const [emailerrorStyle, emailchangeErrorstyle] = useState();

  const eventEmailChangeHandler = (event) => {
    function isValidEmail(email) {
      return /\S+@\S+\.\S+/.test(email);
    }

    if (!isValidEmail(event.target.value)) {
      emailchangeErrorstyle({border: "2px solid red"});
      emailsetError('Email is invalid');
    } else {
      emailchangeErrorstyle({border: "2px solid green"});
      emailsetError(null);
    }
    emailChange(event.target.value);
  }
  // Email field validation//

  //password field validation
  const [password, passwordChange] = useState("");
  const [passworderror, passwordsetError] = useState(null);
  const [passworderrorStyle, passwordchangeErrorstyle] = useState();

  const eventPasswordChangeHandler = (event) => {
    if(!event.target.value.match(/[a-z]/)){
      passwordchangeErrorstyle({border: "2px solid red"});
      passwordsetError('At least 1 lowercase letter');
    }
    else if(!event.target.value.match(/[A-Z]/)){
      passwordchangeErrorstyle({border: "2px solid red"});
      passwordsetError('At least 1 uppercase letter');
    }
    else if(!event.target.value.match(/[0-9]/)){
      passwordchangeErrorstyle({border: "2px solid red"});
      passwordsetError('At least 1 number.');
    }
    else if(event.target.value.length < "8"){
      passwordchangeErrorstyle({border: "2px solid red"});
      passwordsetError('At least 8 characters.');
    }
    else{
      passwordchangeErrorstyle({border: "2px solid green"});
      passwordsetError(null);
    }
    passwordChange(event.target.value);
  }


  const handleSubmit = event => {
    event.preventDefault();
    setLoader(<Loader/>);
    const login_data = {
      email: email,
      password: password
    }

    axios.post(props.url+"/loginapi-view/", login_data)
    .then(function (res) {
      setLoader();
      window.sessionStorage.setItem('User',JSON.stringify(res.data));
      navigate('/guardmanagement');
    })
    .catch(function (error) {
      setLoader();
      document.getElementById("greenmessage").style.display = "none";
      alertCaseChange(error.response.data.message);
    });
  };

    useEffect(() => {
    if (window.sessionStorage.getItem('User') !== null) {
      navigate('/guardmanagement');
    }
    }, [navigate])

  return (
    <div className="wrapper fadeInDown d-flex justify-content-sm-center">
        {loader}
        <div id="formContent">
            <h2 className="active"> Sign In </h2>         
            <div className="fadeIn first">
                <img src={logo} id="icon" alt="User Icon" />
            </div>
            <form onSubmit={handleSubmit}>
                <p className="my-2" id="greenmessage" style={{color: "green"}}>{(location.state !== null)? location.state.message : ""}</p>
                <p className="my-2" style={{color: "red"}}>{alertCase}</p>
                <input style={emailerrorStyle} type="email" className="fadeIn second" placeholder="Email" value={email} onChange={eventEmailChangeHandler}/>
                <br/>{emailerror && <span style={{color: 'red'}}>{emailerror}</span>}
                <input style={passworderrorStyle} type="password" className="fadeIn third" placeholder="password" value={password} onChange={eventPasswordChangeHandler}/>
                <br/>{passworderror && <span style={{color: 'red'}}>{passworderror}</span>}<br/>

                <input type="submit" className="fadeIn fourth" value="Log In"/>
            </form>
            <div id="formFooter">
              <Link className="underlineHover" to="/forgot">Forgot Password?</Link>
            </div>
        </div>
    </div>
  );
}
