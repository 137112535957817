import React, {useState} from 'react'
import './Login.css'
import logo from '../../customlogo/mobiloitte.png';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import Loader from '../Loader';

export default function Forgot(props) {
  const navigate = useNavigate();

  const [otp, setotp] = useState("");
  
  const otpHandler = (event) => setotp(event.target.value);
  
  
  // Email field validation..
  const [useremail, changeemail] = useState("");
  const [errorStyle, changeErrorstyle] = useState();
  const [emailerror, emailsetError] = useState(null);
  const eventEmailChangeHandler = (event) => {
    function isValidEmail(email) {
      return /\S+@\S+\.\S+/.test(email);
    }
    
    if (!isValidEmail(event.target.value)) {
      changeErrorstyle({border: "2px solid red"});
      emailsetError('Email is invalid');
    } else {
      changeErrorstyle({border: "2px solid green"});
      emailsetError(null);
    }
    changeemail(event.target.value);
  }

  //passsword validation
  const [password, passwordChange] = useState("");
  const [passworderror, passwordsetError] = useState(null);
  const [passworderrorStyle, passwordchangeErrorstyle] = useState();

  const PasswordHandler = (event) => {
    if(!event.target.value.match(/[a-z]/)){
      passwordchangeErrorstyle({border: "2px solid red"});
      passwordsetError('At least 1 lowercase letter');
    }
    else if(!event.target.value.match(/[A-Z]/)){
      passwordchangeErrorstyle({border: "2px solid red"});
      passwordsetError('At least 1 uppercase letter');
    }
    else if(!event.target.value.match(/[0-9]/)){
      passwordchangeErrorstyle({border: "2px solid red"});
      passwordsetError('At least 1 number.');
    }
    else if(event.target.value.length < "8"){
      passwordchangeErrorstyle({border: "2px solid red"});
      passwordsetError('At least 8 characters.');
    }
    else{
      passwordchangeErrorstyle({border: "2px solid green"});
      passwordsetError(null);
    }
    passwordChange(event.target.value);
  }

  //Confirm password validation
  const [confirmpassword, changeconfirmpassword] = useState("");
  const [cpassworderror, cpasswordsetError] = useState(null);
  const [cpassworderrorStyle, cpasswordchangeErrorstyle] = useState();

  const cPasswordHandler = (event) => {
    if(!event.target.value.match(/[a-z]/)){
      cpasswordchangeErrorstyle({border: "2px solid red"});
      cpasswordsetError('At least 1 lowercase letter');
    }
    else if(!event.target.value.match(/[A-Z]/)){
      cpasswordchangeErrorstyle({border: "2px solid red"});
      cpasswordsetError('At least 1 uppercase letter');
    }
    else if(!event.target.value.match(/[0-9]/)){
      cpasswordchangeErrorstyle({border: "2px solid red"});
      cpasswordsetError('At least 1 number.');
    }
    else if(event.target.value.length < "8"){
      cpasswordchangeErrorstyle({border: "2px solid red"});
      cpasswordsetError('At least 8 characters.');
    }
    else{
      cpasswordchangeErrorstyle({border: "2px solid green"});
      cpasswordsetError(null);
    }
    changeconfirmpassword(event.target.value);
  }

  const [alert, getalert] = useState("");
  const [alertstyle, changealertstyle] = useState({color: "red"});
  const [loader, setloader] = useState();

  const sendotp = event => {
    event.preventDefault();
    getalert();
    setloader(<Loader/>);

    axios.post(props.url+"/send-otp/", {email: useremail})
    .then(function (response) {
      getalert(response.data.message);
      changealertstyle({color: "green"});
      setloader();
      document.getElementById("sendotp").style.display = "none";
      document.getElementById("conpassword").style.display = "block";
    })
    .catch(function (error) {
      getalert(error.response.data.message);
      changealertstyle({color: "red"});
      setloader();
    });
  };

  const changePasswordHandler = event => {
    event.preventDefault();
    const senddata = {
      email: useremail,
      otp: otp,
      password: password,
      cpassword: confirmpassword
    }

    axios.post(props.url+"/verify-changepass-otp/", senddata)
    .then(function (response) {
      navigate('/', {state:{message: response.data.message}});
    })
    
    .catch(function (error) {
      getalert(error.response.data.message);
      changealertstyle({color: "red"});
    });
  }

  return (
    <div className="wrapper fadeInDown">
        {loader}
        <div id="formContent">
            <h2 className="active"> Forgot Password </h2>
            <div className="fadeIn first">
                <img src={logo} id="icon" alt="User Icon" />
            </div>
            <p id="there_alert" className="my-2" style={alertstyle}>{alert}</p>
            <form id="sendotp" onSubmit={sendotp} >
                <input style={errorStyle} type="email" className="fadeIn second" value={useremail} placeholder="Email" onChange={eventEmailChangeHandler}/>
                <br/>{emailerror && <span style={{color: 'red'}}>{emailerror}</span>}<br/>

                <input type="submit" className="fadeIn fourth" value="Send OTP"/>
            </form>

            <form id="conpassword" style={{display : 'none'}} onSubmit={changePasswordHandler}>
                <input type="text" maxLength={4} value={otp} className="fadeIn second" onChange={otpHandler} placeholder="Enter OTP"/>
                <input style={passworderrorStyle} type="password" value={password} className="fadeIn second" onChange={PasswordHandler}  placeholder="Password"/>
                <br/>{passworderror && <span style={{color: 'red'}}>{passworderror}</span>}<br/>

                <input style={cpassworderrorStyle} type="password" value={confirmpassword} className="fadeIn second" onChange={cPasswordHandler}  placeholder="Confirm Password"/>
                <br/>{cpassworderror && <span style={{color: 'red'}}>{cpassworderror}</span>}<br/>

                <input type="submit" className="fadeIn fourth" value="Change Password"/>
            </form> 

            <div id="formFooter">
              <Link className="underlineHover" to="/">Login</Link>
            </div>
        </div>
    </div>
  );
}
