import React from 'react'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import "./TableView.css";
import Pagination from '@mui/material/Pagination';

var page = 1;
export default function TableView(props) {
  const paginationstyle = {
    margin: "-4rem 0 0 0",
    padding: "1rem", 
    position: "absolute", 
    right: "20px"
  }
  const handlePageChange = (event, newPage) => {
    page = newPage;
    props.passpage(newPage);
  }

  return (
    <>
      <Pagination 
        sx={paginationstyle}
        count={props.apidata?.total} 
        page={page} 
        onChange={handlePageChange} 
        color="primary"
      />
      <Table>
          <Thead>
            {(props?.status === 'Available')?
            (
              <Tr>
                <Th>Date</Th>
                <Th>In Time</Th>
                <Th>Out Time</Th>
                <Th>Available Duration</Th>
              </Tr>
            ):(
              <Tr>
                <Th>Date</Th>
                <Th>Out Time</Th>
                <Th>In Time</Th>
                <Th>Unavailable Duration</Th>
              </Tr>
            )
            }

          </Thead>
          <Tbody>
            {(props?.status === 'Available')? (
              props.apidata.results.map((x) => {
                return (
                  <Tr key={x.id}>
                    <Td>{x.in_time.split("--")[0]}</Td>
                    <Td>{x?.in_time?.split("--")[1]}</Td>
                    <Td>{x?.out_time?.split("--")[1]}</Td>
                    <Td>{x?.available}</Td>
                    {/* <Td>{x?.available}</Td> */}
                  </Tr>
                );
              })
            ):
            (
              props.apidata.results.map((x) => {
                return (
                  <Tr key={x.id}>
                    <Td>{x.in_time.split("--")[0]}</Td>
                    <Td>{x?.out_time?.split("--")[1]}</Td>
                    <Td>{x?.next_in_time?.split("--")[1]}</Td>
                    <Td>{x?.unavailable}</Td>
                    {/* <Td>{x?.available}</Td> */}
                  </Tr>
                );
              })
            )
            }
          </Tbody>
      </Table>
    </>
  );
}
