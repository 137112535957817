import React, { useState} from 'react'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import TableView from './TableView';
import SearchForm from './SearchForm';
import BlockChain from './BlockChain';
import DefaultTableView from './DefaultTableView';
import axios from 'axios';
import SideBar from '../SideBar';
import Heading from '../Heading';
import {URL} from '../auth/Container';
import './Gog.css';


var defaultFlag = false;
var blockPage = 1;
export default function Gog(props) {
  const [blockCom, setBlockCom] = useState();
  const [pageselect, changePageSelect] = useState();
  const buttonalign = {
    textAlign: "left"
  }
  const shadow = {
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px"
  }

  const tablestyle = {
    margin: "3rem",
    background: "white",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
    borderRadius: '1rem'
  }
  
  const defaultPageNumber = (data) => {
    blockPage = data;
  }

  const tableFilterPageHandler = (page) => {
    changePageSelect(page);
  }

  const [viewcomponent, changecomponet] = useState(<DefaultTableView passPage={defaultPageNumber}/>);

  const childdata = (data, status) => {
    defaultFlag = true
    hidebuttons();
    changecomponet(<TableView status={status} passpage={tableFilterPageHandler} apidata = {data}/>);
    setBlockCom(<BlockChain searchapply={true} status={status} apidata = {data} url={URL}/>);
  }

  const listView = () => {
    if(defaultFlag === false){
      changecomponet(<DefaultTableView blockPage={blockPage} passPage={defaultPageNumber}/>);
    }
    document.getElementById("listid").classList.remove('btn-dark');
    document.getElementById("listid").classList.add('btn-light');
    document.getElementById("blockid").classList.remove('btn-light');
    document.getElementById("blockid").classList.add('btn-dark');
  }
  
  const blockchainView = () => {
    if(defaultFlag === false){
      axios
      .get(`${URL}/default-dashboard-api/?page=${blockPage}`)
      .then(res => {
        changecomponet(<BlockChain apidata={res.data} url={URL}/>);
      })
    }
    document.getElementById("listid").classList.remove('btn-light');
    document.getElementById("listid").classList.add('btn-dark');
    document.getElementById("blockid").classList.remove('btn-dark');
    document.getElementById("blockid").classList.add('btn-light');
  }
  
  const [buttons, hidebuttons] = useState(
    <div style={buttonalign}>
       <div className="row MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 MuiGrid-grid-md-2 MuiGrid-grid-lg-2">
      <div className="col-lg-4 col-md-8 col-sm-12 d-grid gap-2 d-md-flex justify-content-md-end">
      <button style={shadow} type="button" className="btn mx-5 my-3 btn-light" id="listid" onClick={listView}>Dashboard Status</button>
      </div>
      <div className="col-lg-4 col-md-8 col-sm-12">
      </div>
      <div class="col-lg-4 col-md-8 col-sm-12 d-grid gap-2 d-md-flex justify-content-md-end">
      <button style={shadow} type="button" className="btn mx-5 my-3 btn-dark" id="blockid" onClick={blockchainView}>Blockchain Explorer</button>
      </div>
      </div>
    </div>
  )

  return (
    <SideBar childComponent={
      <div>
          <Heading title="Guard of Guards"/>
         {buttons}
         <SearchForm pageselect={pageselect} childdata={childdata} shadow = {shadow} url={props.url}/>
         <div style={tablestyle}>
           {viewcomponent}
           <div style={{marginTop: "2rem"}}></div>
           {blockCom}
         </div>
       </div>
    }/>
  );
}
