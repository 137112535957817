import React, {useState} from 'react'
import SideBar from '../SideBar'
import Heading from '../Heading';
import { useFormik } from "formik";
import * as Yup from "yup";
import {URL} from '../auth/Container';
import axios from 'axios';
// import { useNavigate } from "react-router-dom";
import {Link} from  'react-router-dom';
import Loader from '../Loader';
// import $ from 'jquery';
import './UserForm.css';

const userFormValidation = Yup.object({
    uname: Yup.string().min(2, "to short..").max(25).required("Please enter user name."),
    uaddress: Yup.string().min(5, "to short..").max(50).required("Please enter user address."),
    uemail: Yup.string().email("please enter valid Email").required("Please enter user email."),
    password: Yup.string()
            .matches(/[a-z]/, "Must Contain small letter.")
            .matches(/[A-Z]/, "Must Contain capital letter.")
            .matches(/[0-9]/, "Must Contain numerical value.")
            .matches(/[^a-z0-9!._]/gi, "Must Contain special character.")
            .min(8, "Password must contain 8 characters minimum.")
            .required("Please enter user password."),
    cpassword: Yup.string()
      .required("Confirm password is a required field.")
      .oneOf([Yup.ref("password"), null], "Password must match."),
});

const initialValues = {
    uname: "",
    uaddress: "",
    uemail: "",
    password: "",
    cpassword: "",
};
    

export default function AddNewUser() {
    const [loader, setLoader] = useState();
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
        initialValues,
        validationSchema: userFormValidation,
        onSubmit: (values, action) => {
            setLoader(<Loader leftm="35rem"/>);
            axios.post(`${URL}/user-management-api/`, values)
            .then(function (res) {
                setLoader();
                alert(res.data.message);
                action.resetForm();
            })
            .catch(function (error) {
                setLoader();
                alert(error?.response?.data?.message);
            });
        },
    });
    // const navigate = useNavigate();
    // const [addUser, setAddUser] = useState();
    // const addUsereHandler = event => {
    //     setAddUser(event.target.value);
    //     navigate('/user/management', {state:{deviceid: $("#adduser").val()}});
    //     }

  return (
    <SideBar childComponent={
        <>
            <Heading title="User Management"/>
            {loader}
            <div className="container mx-4 my-4" style={{maxWidth: "96%"}}>
            <h2 style={{color: "#000"}}><u>Add New User</u></h2>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-25">
                        <label for="fname">Full Name<span style={{color:"red"}}>*</span></label>
                    </div>
                    <div className="col-75">
                        <input 
                            className="inputClass" 
                            type="text" 
                            name="uname" 
                            placeholder="Enter User Full Name"
                            value={values.uname}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={(errors.uname && touched.uname)?{border:"1px solid red"}:{border:"1px solid green"}}
                        />
                        {errors.uname && touched.uname ? (
                        <p style={{color:"red", fontSize: "14px"}}>{errors.uname}</p>
                        ) : null}
                    </div>
                </div>
                <div className="row">
                    <div className="col-25">
                        <label for="lname">Address<span style={{color:"red"}}>*</span></label>
                    </div>
                    <div className="col-75">
                        <input 
                            className="inputClass" 
                            type="text" 
                            name="uaddress" 
                            placeholder="Enter User Address"
                            value={values.uaddress}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={(errors.uaddress && touched.uaddress)?{border:"1px solid red"}:{border:"1px solid green"}}
                        />
                        {errors.uaddress && touched.uaddress ? (
                        <p style={{color:"red", fontSize: "14px"}}>{errors.uaddress}</p>
                        ) : null}
                    </div>
                </div>
                <div className="row">
                    <div className="col-25">
                        <label for="lname">Valid Email<span style={{color:"red"}}>*</span></label>
                    </div>
                    <div className="col-75">
                        <input 
                            className="inputClass" 
                            type="email" 
                            name="uemail" 
                            placeholder="Enter User Valid Email"
                            value={values.uemail}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={(errors.uemail && touched.uemail)?{border:"1px solid red"}:{border:"1px solid green"}}
                        />
                        {errors.uemail && touched.uemail ? (
                        <p style={{color:"red", fontSize: "14px"}}>{errors.uemail}</p>
                        ) : null}
                    </div>
                </div>
                <div className="row">
                    <div className="col-25">
                        <label for="lname">Password<span style={{color:"red"}}>*</span></label>
                    </div>
                    <div className="col-75">
                        <input 
                            className="inputClass" 
                            type="password" 
                            name="password" 
                            placeholder="Enter User Password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={(errors.password && touched.password)?{border:"1px solid red"}:{border:"1px solid green"}}
                        />
                        {errors.password && touched.password ? (
                        <p style={{color:"red", fontSize: "14px"}}>{errors.password}</p>
                        ) : null}
                    </div>
                </div>
                <div className="row">
                    <div className="col-25">
                        <label for="lname">Confirm Password<span style={{color:"red"}}>*</span></label>
                    </div>
                    <div className="col-75">
                        <input 
                            className="inputClass" 
                            type="password" 
                            name="cpassword" 
                            placeholder="Enter User Confirm Password"
                            value={values.cpassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={(errors.cpassword && touched.cpassword)?{border:"1px solid red"}:{border:"1px solid green"}}
                        />
                        {errors.cpassword && touched.cpassword ? (
                        <p style={{color:"red", fontSize: "14px"}}>{errors.cpassword}</p>
                        ) : null}
                    </div>
                </div>
                <div className="row mx-4 my-4">
                    <div className="col-50">
                        <Link to="/user/management" className="btn btn-dark mx-2" style={{width: "5rem"}}>Back</Link>
                        {/* <button type="submit" className="btn btn-dark mx-2" style={{width: "5rem"}} value={addUser} id="adduser" onClick={addUsereHandler}>Submit</button> */}
                        <button type="submit" className="btn btn-dark mx-2" style={{width: "5rem"}}>Submit</button>
                    </div>
                </div>
            </form>
            </div>
        </>
    }/>
  )
}
