import React, {useState} from 'react';
import SideBar from '../SideBar';
import { Table, Tbody } from 'react-super-responsive-table';
import { useLocation } from "react-router-dom";
import axios from 'axios';
import { useEffect } from 'react';
import './ViewDevice.css';
import { Link } from 'react-router-dom';
import {URL} from '../auth/Container';

export default function ViewDevice() {
    const location = useLocation();
    var [apiresponse, changeApiResponse] = useState("");

    useEffect(() => {
        axios
        .get(`${URL}/device-data-api/?id=${location.state.device_id}`)
        .then(res => {
            changeApiResponse(res.data.data);
        });
    }, [location]);

    const h1_style = {
        color: "#fff",
        background: "#000",
        padding: "0.5rem",
        fontSize: "2rem"
    }

    const mainDiv = {
        background: "#fff", 
        margin: "1.5rem", 
        borderRadius: "1rem"
    }

    return (
    <SideBar childComponent = {
        <>
            <div id="mainDiv" style={mainDiv}>
                <h1 style={h1_style}>Device Details</h1>
                <Table>
                    <Tbody>
                        <tr className="trTable"><td><img src={`${URL}${apiresponse.qr_code}`} height="150px" width="150px" alt="" /></td></tr>
                        {JSON.parse(window.sessionStorage.getItem("User"))?.user_type === "Admin" && <tr className="trTable"><td><b>Device ID:</b> {apiresponse.device_id}</td></tr>}
                        <tr className="trTable"><td><b>Location:</b> {apiresponse.location}</td></tr>
                        {JSON.parse(window.sessionStorage.getItem("User"))?.user_type === "Admin" && <tr className="trTable"><td><b>IP Address:</b> {apiresponse.ip}</td></tr>}
                        <tr className="trTable"><td><b>Device Type:</b> {apiresponse.device_type}</td></tr>
                        <tr className="trTable"><td><b>Tx Hash:</b> {apiresponse.tx_hash}</td></tr>
                        <tr className="trTable"><td><b>Block Hash:</b> {apiresponse.block_hash}</td></tr>
                    </Tbody>
                </Table>
            </div>
            <Link to="/guardmanagement" style={{marginBottom: "2rem"}} className="btn btn-dark">Back</Link>
        </>
    }/>
  )
}
