import React, {useState} from 'react'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import {URL} from '../auth/Container';
import axios from 'axios';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './DeleteConfirm.css';
import Pagination from '@mui/material/Pagination';

export default function UserManagement(props) {
    const [apires, setapiRes] = useState();
    const [deletehandle, setdeleteHandle] = useState();
    const [page, setPage] = useState(1);
    const [countpage, setCountPage] = useState();
    const paginationstyle = {
        margin: "-4rem 0 0 0",
        padding: "1rem", 
        position: "absolute", 
        right: "20px"
    }
    const tablestyle = {
        margin: "3rem",
        background: "white",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
        borderRadius: '1rem'
    }    

    useEffect(() => {
        const btnstyle = {
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
            fontSize: "0.8rem",
            height: "2.5rem",
            width: "2.5rem"
        }
        let fullname = (props?.fullname)? props?.fullname : "";
        let fromDate = (props?.apifromDate)? props?.apifromDate : "";
        let toDate = (props?.apitoDate)? props?.apitoDate : "";
        axios.get(`${URL}/user-management-api/?page=${page}&name=${fullname}&from=${fromDate}&to=${toDate}`)
        .then(function (res) {
            setCountPage(res?.data?.total);
            setapiRes(
                res?.data?.results.map((x) => {
                    const handleClick = event => {
                        setdeleteHandle(event.currentTarget.id);
                    };
                    return (
                        <Tr key={x.id}>
                            <Td>{x?.full_name}</Td>
                            <Td>{x?.email}</Td>
                            <Td>{x?.created_at}</Td>
                            <Td>{x?.last_login}</Td>
                            <Td>{x?.address}</Td>
                            <Td>
                                <Link to={`/view/user/${x.id}`} style={btnstyle} className='btn btn-info mx-1 my-1'><span style={{marginTop:"2px", marginLeft: "-4px"}} class="material-symbols-outlined">visibility</span></Link>
                                <Link to={`/edit/user/${x.id}`} style={btnstyle} className='btn btn-success mx-1 my-1'><span style={{marginTop:"2px", marginLeft: "-4px"}} class="material-symbols-outlined">edit</span></Link>
                                <a href="#myModal" onClick={handleClick} style={btnstyle} id={x.id} className="btn btn-danger mx-1 my-1 trigger-btn" data-toggle="modal"><span style={{marginTop:"2px", marginLeft: "-4px"}} class="material-symbols-outlined">delete_forever</span></a>
                            </Td>
                        </Tr>
                    );
                })
            )
        })
        .catch(function (error) {
            setPage(1);
        });
        }, [deletehandle, page, props?.fullname, props?.apifromDate, props?.apitoDate])

    const confirmDeleteHandler = (event) => {
        axios.delete(`${URL}/user-management-api/?id=${event.currentTarget.id}`)
        .then(function(){
            window.location.reload(false);
        })
    }

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    }

    return (
        <>
            <Pagination 
                sx={paginationstyle}
                count={countpage} 
                page={page} 
                onChange={handlePageChange} 
                color="primary"
            />
            <div style={tablestyle}>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>Full Name</Th>
                            <Th>Email</Th>
                            <Th>Created At</Th>
                            <Th>Last Login</Th>
                            <Th>Address</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {apires}
                    </Tbody>
                </Table>
            </div>

            <div id="myModal" class="modal fade">
                <div class="modal-dialog modal-confirm">
                    <div class="modal-content">
                        <div class="modal-header flex-column">
                            <div class="icon-box">
                                <i class="material-icons">&#xE5CD;</i>
                            </div>						
                            <h4 class="modal-title w-100">Are you sure?</h4>	
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                        </div>
                        <div class="modal-body">
                            <p>Do you really want to delete these User? This process cannot be undone.</p>
                        </div>
                        <div class="modal-footer justify-content-center">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            <button id={deletehandle} onClick={confirmDeleteHandler} type="button" class="btn btn-danger" data-dismiss="modal">Delete</button>
                        </div>
                    </div>
                </div>
            </div>     
        </>
    )
}
