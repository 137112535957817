import React, {useState, useEffect} from 'react'
import SideBar from '../SideBar';
import './AddDevice.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loader from '../Loader';
import {URL} from '../auth/Container';
import Heading from '../Heading';

export default function EditDevice() {
  const location = useLocation();  
  const navigate = useNavigate();

  const [loader, changeLoader] = useState();
  const [txhashState, settxhashState] = useState();
  const [deviceIdstate, setdeviceIdstate] = useState();
  const [locationstate, setlocationstate] = useState();
  const [ipAddressstate, setipAddressstate] = useState();
  const [deviceTypestate, setdeviceTypestate] = useState();
  const [blockhash, setblockhash] = useState();

  const deviceId = location.state.deviceid;
  
  useEffect(() => {
    axios
    .get(`${URL}/device-data-api/?id=${deviceId}`)
    .then(res => {
      const resp = res.data.data;
      setdeviceIdstate(resp.device_id);
      setlocationstate(resp.location);
      setipAddressstate(resp.ip);
      setdeviceTypestate(resp.device_type);
    });

  },[deviceId])
  
  const deviceChangeHandler = event => setdeviceIdstate(event.target.value);
  const locationChangeHandler = event => setlocationstate(event.target.value);
  const ipAddressChangeHandler = event => setipAddressstate(event.target.value);
  const deviceTypeChangeHandler = event => setdeviceTypestate(event.target.value);
  const txhashChangeHandler = event => settxhashState(event.target.value);



  const generateBlockChain = event => {
    changeLoader(<Loader leftm="35rem"/>);
    event.preventDefault();
    const apibody = {
      device_id: (deviceIdstate)? deviceIdstate: "",
      device_type: (deviceTypestate) ? deviceTypestate: "",
      location: (locationstate)? locationstate:"",
      ip_address: (ipAddressstate)? ipAddressstate: "",
      id: deviceId
    }
    document.getElementById("deviceid").setAttribute("readonly", true);
    document.getElementById("location").setAttribute("readonly", true);
    document.getElementById("ip_address").setAttribute("readonly", true);
    document.getElementById("device_type").setAttribute("readonly", true);
    document.getElementById("hashGenerateBtn").disabled = true;
    
    axios.post(URL+"/edit-device-hash/", apibody)
    .then(function (res) {
      changeLoader();
      settxhashState(res.data.data.tx_hash);
      setblockhash(res.data.data.block_hash);
    })
    .catch(function (error) {
      changeLoader();
      document.getElementById("hashGenerateBtn").disabled = false;
      alert(error.response.data.message);
    });
  }

  const editDeviceFormHandler = event => {
    event.preventDefault();
    const apibody = {
      id: deviceId,
      tx_hash: txhashState,
      block_hash: blockhash,
      device_id: deviceIdstate,
      location: locationstate,
      ip_address: ipAddressstate,
      device_type: deviceTypestate
    }

    axios.post(URL+"/edit-device-success/", apibody)
    .then(function (res) {
      navigate('/guardmanagement');
    })
    .catch(function (error) {
    });
  }
  
  return (
    <SideBar childComponent={
      <>
      <Heading title="Guard of Guards"/>
      <div className="container mx-4 my-4" style={{maxWidth: "96%"}}>
        {loader}
        <h2 style={{color: "#000"}}><u>Edit Device</u></h2>
        <form onSubmit={editDeviceFormHandler}>
          <div className="row">
            <div className="col-25">
              <label for="fname">Device ID</label>
            </div>
            <div className="col-75">
              <input value={deviceIdstate} id="deviceid" onChange={deviceChangeHandler} className="inputClass" type="text" placeholder="Enter New Device Id"/>
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <label for="lname">Location</label>
            </div>
            <div className="col-75">
              <input value={locationstate} id="location" onChange={locationChangeHandler} className="inputClass" type="text" placeholder="Enter New Location"/>
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <label for="lname">IP Address</label>
            </div>
            <div className="col-75">
              <input value={ipAddressstate} id="ip_address" onChange={ipAddressChangeHandler} className="inputClass" type="text" placeholder="Enter New IP Address"/>
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <label for="lname">Device Type</label>
            </div>
            <div className="col-75">
              <input value={deviceTypestate} id="device_type" onChange={deviceTypeChangeHandler} className="inputClass" type="text" placeholder="Enter New Device Type" readOnly/>
            </div>
          </div>
          <div className="row">
            <div className="col-25">
              <label>Device Blockchain</label>
            </div>
            <div className="col-75">
              <input className="inputClass" type="text" value={txhashState} onChange={txhashChangeHandler} placeholder="Auto Generate" readOnly/>
            </div>
            <div className="col-50">
              <button type="submit" id="hashGenerateBtn" className="btn btn-dark mx-2 my-2" onClick={generateBlockChain}>Generate Blockchain</button>
            </div>
          </div>

          <div className="row my-4 mx-4">
          <table>
            <thead>
              <tr>
                <th>DEVICE INSTALLATION STATUS</th>
                <th>OPERATIONS</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Installation Checked</td>
                <td><input type="checkbox" required /></td>
              </tr>

              <tr>
                <td>Networking Protocol Checked</td>
                <td><input type="checkbox" required /></td>
              </tr>

              <tr>
                <td>Data Communication Checked</td>
                <td><input type="checkbox" required /></td>
              </tr>
            </tbody>
          </table>
          </div>
          <div className="row">
            <div className="col-50">
              <Link to='/guardmanagement' className="btn btn-dark mx-2" style={{width: "5rem"}}>Back</Link>
              <button type="submit" className="btn btn-dark mx-2" style={{width: "5rem"}}>Submit</button>
            </div>
          </div>
        </form>
      </div>
      </>
    }/>
  );
}
