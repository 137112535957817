import React from 'react'
import { Sidebar, SidebarItem } from 'react-responsive-sidebar';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";


export default function SideBar(props) {
  const navigate = useNavigate();
  const handlerLogout =()=>{
    window.sessionStorage.clear();
    navigate('/');
  }

  var items = [
    <Link to="/guardmanagement" style={{width: "20rem"}}><SidebarItem textAlign="left" hoverHighlight="#11999E">Guard of Guards</SidebarItem></Link>,
    <Link to="/user/management" style={{width: "20rem"}}><SidebarItem textAlign="left" hoverHighlight="#11999E">User Management</SidebarItem></Link>,
    <Link to="/" style={{width: "20rem"}}><SidebarItem onClick={handlerLogout} textAlign="left" hoverHighlight="#870107"><b>Logout</b></SidebarItem></Link>,
  ];

  if (JSON.parse(window.sessionStorage.getItem("User"))?.user_type !== "Admin"){
    items.splice(1, 1);
  }

  return (
    <div style={{background: 'red'}}>
        <Sidebar content={items} background="#252A34">
          {props.childComponent}
          <p className='card-footer text-muted' style={{position: "absolute", width: "100%"}}>Copyright © 2022 Mobiloitte</p>
        </Sidebar>
    </div>
  )
}
