import React, {useEffect, useState} from 'react'
import SideBar from '../SideBar'
import { Link, useParams } from 'react-router-dom';
import { useFormik } from "formik";
import Heading from '../Heading';
import * as Yup from "yup";
import axios from 'axios';
import {URL} from '../auth/Container';
import Loader from '../Loader';
import './UserForm.css';

const userFormValidation = Yup.object({
    cname: Yup.string().min(2, "to short..").max(25).required("Please enter user name."),
    caddress: Yup.string().min(5, "to short..").max(50).required("Please enter user address."),
    cemail: Yup.string().email("please enter valid Email").required("Please enter user email."),
    newpassword: Yup.string()
            .matches(/[a-z]/, "Must Contain small letter.")
            .matches(/[A-Z]/, "Must Contain capital letter.")
            .matches(/[0-9]/, "Must Contain numerical value.")
            .matches(/[^a-z0-9!._]/gi, "Must Contain special character.")
            .min(8, "Password must contain 8 characters minimum."),
    cnewpassword: Yup.string()
      .oneOf([Yup.ref("newpassword"), null], "Password must match."),
});


const initialValues = {
    cname: "",
    caddress: "",
    cemail: "",
    newpassword: "",
    cnewpassword: "",
};

export default function EditUser() {
    const params = useParams();
    const [loader, setLoader] = useState();
    const [apiResponse, setApiResponse] = useState();
    useEffect(() => {
      axios.get(`${URL}/get-singleuser-api/?id=${params.id}`)
      .then(function (res) {
        setApiResponse(res?.data?.data);
      })
    }, [params])
    initialValues.cname = apiResponse?.full_name
    initialValues.caddress = apiResponse?.address
    initialValues.cemail = apiResponse?.email

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
        initialValues,
        validationSchema: userFormValidation,
        onSubmit: (values, action) => {
            setLoader(<Loader leftm="35rem"/>);
            axios.put(`${URL}/user-management-api/`, Object.assign({}, values, params))
            .then(function (res) {
                setLoader();
                window.location.reload(false);
                alert(res?.data?.message);
                action.resetForm();
            })
            .catch(function (error) {
                setLoader();
                alert(error?.response?.data?.message);
            });
            console.log(values);
            action.resetForm();
        },
    });

    
    return (
    <SideBar childComponent={
        <>
            <Heading title="User Management"/>
            {loader}
            <div className="container mx-4 my-4" style={{maxWidth: "96%"}}>
            <h2 style={{color: "#000"}}><u>Edit User</u></h2>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-25">
                        <label for="fname">Full Name<span style={{color:"red"}}>*</span></label>
                    </div>
                    <div className="col-75">
                        <input 
                            className="inputClass" 
                            type="text" 
                            name="cname" 
                            placeholder="Enter User Full Name"
                            value={values.cname}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={(errors.cname && touched.cname)?{border:"1px solid red"}:{border:"1px solid green"}}
                        />
                        {errors.cname && touched.cname ? (
                        <p style={{color:"red", fontSize: "14px"}}>{errors.cname}</p>
                        ) : null}
                    </div>
                </div>
                <div className="row">
                    <div className="col-25">
                        <label for="lname">Address<span style={{color:"red"}}>*</span></label>
                    </div>
                    <div className="col-75">
                        <input 
                            className="inputClass" 
                            type="text" 
                            name="caddress" 
                            placeholder="Enter User Address"
                            value={values.caddress}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={(errors.caddress && touched.caddress)?{border:"1px solid red"}:{border:"1px solid green"}}
                        />
                        {errors.caddress && touched.caddress ? (
                        <p style={{color:"red", fontSize: "14px"}}>{errors.caddress}</p>
                        ) : null}
                    </div>
                </div>
                <div className="row">
                    <div className="col-25">
                        <label for="lname">Valid Email<span style={{color:"red"}}>*</span></label>
                    </div>
                    <div className="col-75">
                        <input 
                            className="inputClass" 
                            type="email" 
                            name="cemail" 
                            placeholder="Enter User Valid Email"
                            value={values.cemail}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={(errors.cemail && touched.cemail)?{border:"1px solid red"}:{border:"1px solid green"}}
                        />
                        {errors.cemail && touched.cemail ? (
                        <p style={{color:"red", fontSize: "14px"}}>{errors.cemail}</p>
                        ) : null}
                    </div>
                </div>
                <div className="row">
                    <div className="col-25">
                        <label for="lname">Password</label>
                    </div>
                    <div className="col-75">
                        <input 
                            className="inputClass" 
                            type="password" 
                            name="newpassword" 
                            placeholder="Enter User password"
                            value={values.newpassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={(errors.newpassword && touched.newpassword)?{border:"1px solid red"}:{border:"1px solid green"}}
                        />
                        {errors.newpassword && touched.newpassword ? (
                        <p style={{color:"red", fontSize: "14px"}}>{errors.newpassword}</p>
                        ) : null}
                    </div>
                </div>
                <div className="row">
                    <div className="col-25">
                        <label for="lname">Confirm Password</label>
                    </div>
                    <div className="col-75">
                        <input 
                            className="inputClass" 
                            type="password" 
                            name="cnewpassword" 
                            placeholder="Enter User Confirm password"
                            value={values.cnewpassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={(errors.cnewpassword && touched.cnewpassword)?{border:"1px solid red"}:{border:"1px solid green"}}
                        />
                        {errors.cnewpassword && touched.cnewpassword ? (
                        <p style={{color:"red", fontSize: "14px"}}>{errors.cnewpassword}</p>
                        ) : null}
                    </div>
                </div>
                <div className="row mx-4 my-4">
                    <div className="col-50">
                        <Link to="/user/management" className="btn btn-dark mx-2" style={{width: "5rem"}}>Back</Link>
                        <button type="submit" className="btn btn-dark mx-2" style={{width: "5rem"}}>Submit</button>
                    </div>
                </div>
            </form>
            </div>
        </>
    }/>
  )
}
