import React, {useState, useEffect} from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import "./TableView.css";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import moment from 'moment/moment';
import {URL} from '../auth/Container';

export default function DefaultTableView(props) {
    const [tabledata, changeTabledata] = useState("");
    const [page, changePage] = useState((props.blockPage)?props.blockPage:1);
    const [count, changeCount] = useState();
    var navigate = useNavigate();

    const paginationstyle = {
        margin: "-4rem 0 0 0",
        padding: "1rem", 
        position: "absolute", 
        right: "20px"
    }
    
    useEffect(() => {
        const shadowType = {boxShadow: "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px"}
        axios
        .get(`${URL}/default-dashboard-api/?page=${page}`)
        .then(res => {
            changeCount(res.data.total);
            changeTabledata(
                res.data.results.map((x) => {
                    var colorstatus;
                    var timeDisplay;
                    var unavailable;
                    var available;
                    if (x?.state === "True"){
                        colorstatus = {background: "green"}
                    }else{
                        colorstatus = {background: "red"}
                    }

                    if (x?.out_time !== null){
                        // Nov. 21, 2022--09:03 PM
                        timeDisplay = x?.out_time?.split("--")[1]
                        var now = moment().format('MMMM. Do, YYYY--h:mm a'); 
                        var ms = moment.duration(moment(now,"MMMM. Do, YYYY--h:mm a").diff(moment(x?.out_time,"MMMM. Do, YYYY--h:mm a")));
                        var days = parseInt(ms?.asDays());
                        var hours = parseInt(ms?.asHours());
                        hours = hours - days*24;
                        var minutes = parseInt(ms?.asMinutes());
                        minutes = minutes - (days*24*60 + hours*60);
                        (days === 0)?(unavailable = `${hours} h, ${minutes} m`):(unavailable = `${days} Days, ${hours} h, ${minutes} m`)
                        available = "Not Available in Place"
                    }else{
                        timeDisplay = x?.in_time?.split("--")[1]
                        now = moment().format('MMMM. Do, YYYY--h:mm a'); 
                        ms = moment.duration(moment(now,"MMMM. Do, YYYY--h:mm a").diff(moment(x?.in_time,"MMMM. Do, YYYY--h:mm a")));
                        days = parseInt(ms?.asDays());
                        hours = parseInt(ms?.asHours());
                        hours = hours - days*24;
                        minutes = parseInt(ms?.asMinutes());
                        minutes = minutes - (days*24*60 + hours*60);
                        (days === 0)?(available = `${hours} h, ${minutes} m`):(available = `${days} Days, ${hours} h, ${minutes} m`)
                        unavailable = "Available in Place"
                    }

                    const ViewDeviceHandler = event => {
                        event.preventDefault();
                        navigate("/view/device", {state: {device_id: x?.device_data}});
                    }

                    props.passPage(page);
                    
                    return (
                        <>
                            {(x?.available !== "test")? (
                                <Tr key={x?.id}>
                                    <Td>{x?.in_time?.split("--")[0]}</Td>
                                    <Td>{x?.location}</Td>
                                    <Td><div style={Object.assign({}, {marginLeft: "3rem", height: "1.5rem", width: '1.5rem', textAlign: "center"}, colorstatus, shadowType)} className="rounded-circle"></div></Td>
                                    <Td>{timeDisplay}</Td>
                                    <Td>{unavailable}</Td>
                                    <Td>{available}</Td>
                                    <Td><button className='btn btn-info' onClick={ViewDeviceHandler} style={Object.assign({}, shadowType, {height: "2.5rem", width: "2.5rem"})}><span style={{marginTop:"2px", marginLeft:"-4px"}} class="material-symbols-outlined">visibility</span></button></Td>
                                </Tr>
                            ):(
                                <Tr key={x?.id}>
                                    <Td>--</Td>
                                    <Td>{x?.location}</Td>
                                    <Td><div style={Object.assign({}, {marginLeft: "3rem", height: "1.5rem", width: '1.5rem', textAlign: "center"}, colorstatus, shadowType)} className="rounded-circle"></div></Td>
                                    <Td>--</Td>
                                    <Td>--</Td>
                                    <Td>--</Td>
                                    <Td><button className='btn btn-info' onClick={ViewDeviceHandler} style={Object.assign({}, shadowType, {height: "2.5rem", width: "2.5rem"})}><span style={{marginTop:"2px", marginLeft:"-4px"}} class="material-symbols-outlined">visibility</span></button></Td>
                                </Tr>
                            )}
                        </>
                    );
                })
            );
        });
    }, [navigate, changeCount, page, props?.passPage]);
    
    const handlePageChange = (event, newPage) => {
        changePage(newPage);
    }
    return (    
    <>
        <Pagination 
            sx={paginationstyle}
            count={count} 
            page={page} 
            onChange={handlePageChange} 
            color="primary"
        />
        <Table>
            <Thead >
                <Tr>
                <Th>Date</Th>
                <Th>Location</Th>
                <Th>Current Status</Th>
                <Th>Time</Th>
                <Th>Unavailable Time</Th>
                <Th>Available Time</Th>
                <Th>View Device</Th>
                </Tr>
            </Thead>
            <Tbody>
                {tabledata}
            </Tbody>
        </Table>
    </>
  );
}
