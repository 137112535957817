import { Bars } from  'react-loader-spinner';
import React from 'react';
import './Loader.css';

export default function Loader(props) {
    const loaderDisgn = {
        position: 'absolute',
        zIndex: '1',
        align:"center",
        marginLeft: props.leftm,
        marginTop: "15rem"
    }

    return (
        <Bars
            height="80"
            width="80"
            color="#FF2E63"
            visible={true}
            wrapperStyle={loaderDisgn}
            wrapperClass="loaderclass"
        />
    );
}
