import React, {useState} from 'react'
import SideBar from '../SideBar'
import Heading from '../Heading'
import { useParams } from 'react-router-dom'
import { Table, Tbody } from 'react-super-responsive-table';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';
import {URL} from '../auth/Container';

export default function ViewUser() {
  const h1_style = {
    color: "#fff",
    background: "#000",
    padding: "0.5rem",
    fontSize: "2rem"
  }
  const mainDiv = {
    background: "#fff", 
    margin: "1.5rem", 
    borderRadius: "1rem"
  }

  const params = useParams();
  const [apiresponse, setApiResponse] = useState();
  useEffect(() => {
    axios.get(`${URL}/get-singleuser-api/?id=${params.id}`)
    .then(function (res) {
      setApiResponse(res?.data?.data);
    })
  }, [params])
  
  return (
    <SideBar childComponent={
      <>
        <Heading title="User Management"/>
        <div id="mainDiv" style={mainDiv}>
          <h1 style={h1_style}>View User</h1>
          <Table>
              <Tbody>
                <tr className="trTable"><td><img src={`${URL}${apiresponse?.qr_code}`} height="150px" width="150px" alt="" /></td></tr>
                <tr className="trTable"><td><b>Full Name: </b> {apiresponse?.full_name}</td></tr>
                <tr className="trTable"><td><b>Email: </b> {apiresponse?.email}</td></tr>
                <tr className="trTable"><td><b>Created At: </b> {apiresponse?.created_at}</td></tr>
                <tr className="trTable"><td><b>Last Login: </b> {apiresponse?.last_login}</td></tr>
                <tr className="trTable"><td><b>Address: </b> {apiresponse?.address}</td></tr>
                <tr className="trTable"><td><b>Tx Hash: </b> {apiresponse?.tx_hash}</td></tr>
                <tr className="trTable"><td><b>Block Hash: </b> {apiresponse?.block_hash}</td></tr>
              </Tbody>
          </Table>
        </div>
        <Link to="/user/management" style={{marginBottom: "2rem"}} className="btn btn-dark">Back</Link>
      </>
    }/>
  )
}
