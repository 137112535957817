import React, {useState, useEffect} from 'react'
import axios from 'axios';
import './SearchForm.css';
import { Link, useNavigate } from "react-router-dom";
import $ from 'jquery';
import {URL} from '../auth/Container';

var searchClick = false;
var pageobj = 1;
export default function SearchForm(props) {
  const navigate = useNavigate();
  const [location, apiLocation] = useState("");
  const [pagelocation, changePagelocation] = useState();
  const [fromdate, fromdatechange] = useState();
  const [todate, todatechange] = useState();
  const [editDevice, setEditdevice] = useState();
  const [downloadReport, setDownloadReport] = useState();


  const locationsearch = event => changePagelocation(event.target.value);
  const fromdatesearch = event => fromdatechange(event.target.value);
  const todatesearch = event => todatechange(event.target.value);
  const editDeviceHandler = event => {
    setEditdevice(event.target.value);
    navigate('/edit/device', {state:{deviceid: $("#editdevice").val()}});
  }
  const [apibody, changeApiBody] = useState();

  const submitSearchHandler = async event => {
    event.preventDefault();
    searchClick = true
    changeApiBody({
      location: pagelocation,
      from: fromdate,
      to: todate,
      status: document.getElementById("statusid").value
    })
  }

  useEffect(() => {
    if (searchClick){
      pageobj = 1
    }else{
      pageobj = (props?.pageselect)? props?.pageselect: 1
    }
    
    axios.post(`${URL}/filter-data-api/?page=${pageobj}`, apibody)
    .then(function (res) {
      setDownloadReport(
        <a 
        className="btn btn-success mx-4 my-3" 
        style={props.shadow} 
        href={`${URL}/download-record/?deviceid=${pagelocation}&status=${document.getElementById("statusid").value}&from=${fromdate}&to=${todate}`}
        >Download Report
        </a>
      )
      props.childdata(res.data, apibody.status);
    })
    .catch(function (error) {
      // alert("error")
    });
    searchClick = false;
  },[props?.pageselect, props.shadow, apibody, pagelocation])

  useEffect(() => {
      axios
      .get(URL+"/list-of-device-api/")
      .then(res => {
        apiLocation(res.data.data.map((x) => (
          <option key={x.id} value={x.id}>{x.location}</option>
        )))
      });
  },[])

  const resetHandler = () => {
    window.location.reload(false);
  }

  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth()+1; //January is 0 so need to add 1 to make it 1!
  var yyyy = today.getFullYear();
  if(dd<10){
    dd='0'+dd
  } 
  if(mm<10){
    mm='0'+mm
  } 
  today = yyyy+'-'+mm+'-'+dd;
  
  $("#datefromid").on('click',function(){
    $("#datefromid").attr("max",today)
  })
  $("#datefromid").change(function(){
    let min = $("#datefromid").val()
    $("#datetoid").val("");
    $("#datetoid").attr("min",min)
    $("#datetoid").attr("max",today)
  })
  $("#datetoid").on('click',function(){
    $("#datetoid").attr("max",today)
  })


  return (
    <div className="display: flex justify-content: center MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-3 MuiGrid-align-items-xs-center MuiGrid-justify-content-xs-center">
      <form  onSubmit={submitSearchHandler}>
        <div className="MuiBox-root jss113 jss107 ">
          <div className="row MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 MuiGrid-grid-md-2 MuiGrid-grid-lg-2">
            <div className="col-md-3 col-sm-6">
              <label className='mx-3 my-2'>Status: </label>
                <select className="btn btn-light" id="statusid" required>
                  <option value="">Select Status</option>
                  <option value="Unavailable">Unavailable</option>
                  <option value="Available">Available</option>
                </select>
              </div>
              <div className="col-md-3 col-sm-6">
              <label className='mx-3 my-2' >Loc:</label>
                <select className="btn btn-light" value={pagelocation} id="locationid" onChange={locationsearch} required>
                  <option key={0} value="">SelectLocation</option>
                  {location}
                </select>
              </div>
              <div className="col-md-3 col-sm-6">
                <label className='mx-3 my-2'>From:</label>
                <input type="date" value={fromdate} onChange={fromdatesearch} className="btn btn-light mx-3 my-2" id="datefromid" required/>
              </div>
              <div className="col-md-3 col-sm-6">
              <label>To:</label>
              <input type="date" value={todate} onChange={todatesearch} className="btn btn-light mx-3 my-2" id="datetoid" required/>
            </div>
            </div>
          </div>
         
          
         
       


          <div className='MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 MuiGrid-grid-md-2 MuiGrid-grid-lg-2'>
            <div className="row MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-4 MuiGrid-grid-md-2 MuiGrid-grid-lg-2">
            <div className="col-md-3 col-sm-6">
            </div>
              <div className="col-md-3 col-sm-6">
                <button type="submit" className="btn btn-light mx-4 my-3" style={props.shadow}>Search</button>
              
                <Link onClick={resetHandler} className="btn btn-light mx-4 my-3" style={props.shadow}>Reset</Link>
              </div>
              {(JSON.parse(window.sessionStorage.getItem("User"))?.user_type === "Admin") && 
              
              <>
              <div className="col-md-3 col-sm-6">
                <Link to="/add/device" className="btn btn-light mx-4 my-3" style={props.shadow}>Add New Device</Link>
              </div>
                <div className="col-md-3 col-sm-6">
                <select className="btn btn-light mx-3 my-2" style={props.shadow} value={editDevice} id="editdevice" onChange={editDeviceHandler}>
                  <option key={0} value="">Edit Device</option>
                  {location}
                </select>
              </div>
              </>}
              
            </div>
            {downloadReport}
          </div>

        
      </form>
    </div>
  )
}
